import { DefaultTheme } from "@nehalist/gatsby-theme-nehalem";

import backgroundImage from "../../../../content/assets/images/cropped-FVRsunset.jpg";

const Theme: DefaultTheme = {
  layout: {
    backgroundColor: `#fafafa`,
    primaryColor: `#477f1b`,
    linkColor: `#102132`
  },
  breakpoints: {
    xs: `425px`,
    sm: `576px`,
    md: `768px`,
    lg: `992px`,
    xl: `1300px`
  },
  fonts: {
    base:
      `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, ` +
      `Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`
  },
  components: {
    container: {
      width: `1260px`
    },
    header: {
      height: `440px`,
      background: `url(${backgroundImage}) top / contain scroll no-repeat`
    }
  }
};

export default Theme;
